import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import './App.css';
import TrackDetails from './TrackDetails';
import AuthPage from './AuthPage';
import Parse from './ParseConfig';
import Leaderboards from './Leaderboards';
import logo from './logo.png'; 
import LogPage from './LogPage';
import Profile from './Profile';

function App() {
  // check if user is currently logged in
  const isLoggedIn = Parse.User.current() !== null;

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <Link to="/" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <ul>
            {isLoggedIn && (
              <>
                <li>
                  <Link to="/profile"> My Profile</Link>
                </li>
                <li>
                  <Link to="/track-details">Records</Link>
                </li>
                <li>
                  <Link to="/leaderboards">Leaderboards</Link>
                </li>
                <li>
                  <Link to="/log">Log</Link>
              </li>
              </>
            )}

            <div style={{marginLeft: 'auto'}}>
            <li>
              {isLoggedIn ? (
                <button onClick={() => Parse.User.logOut()}>Logout</button>) : (<Link to="/auth">Login/Register</Link>)}
            </li>
            </div>

          </ul>
        </nav>
        <Routes>
          <Route path="/" element={
            <header className="App-header">
              <h1>Welcome to Mario Kart Wii Tracker</h1>
              <p>Track and compare your Mario Kart times and splits across various tracks</p>
            </header>
          } />
          <Route path="/track-details" element={isLoggedIn ? <TrackDetails /> : <Navigate replace to="/auth" />} />
          <Route path="/leaderboards" element={isLoggedIn ? <Leaderboards /> : <Navigate replace to="/auth" />} />
          <Route path="/log" element={isLoggedIn ? <LogPage /> : <Navigate replace to="/auth" />} />
          <Route path="/auth" element={!isLoggedIn ? <AuthPage /> : <Navigate replace to="/" />} />
          <Route path="/profile" element={isLoggedIn ? <Profile /> : <Navigate replace to="/auth" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
