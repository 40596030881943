// src/Auth.js
import Parse from './ParseConfig';

export const registerUser = async (username, password) => {
  const user = new Parse.User();
  user.set("username", username);
  user.set("password", password);

  try {
    await user.signUp();
    return { success: true };
  } catch (error) {
    console.error('Error while signing up user', error);
    return { success: false, error: error.message };
  }
};

export const loginUser = async (username, password) => {
  try {
    await Parse.User.logIn(username, password);
    return { success: true };
  } catch (error) {
    console.error('Error while logging in user', error);
    return { success: false, error: error.message };
  }
};

export const logoutUser = async () => {
  try {
    await Parse.User.logOut();
    return { success: true };
  } catch (error) {
    console.error('Error while logging out user', error);
    return { success: false, error: error.message };
  }
};
