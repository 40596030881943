// src/AuthPage.js
import React, { useState } from 'react';
import { registerUser, loginUser, logoutUser } from './Auth';

function AuthPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleRegister = async () => {
    const result = await registerUser(username, password);
    if (!result.success) {
      setError(result.error);
    }
  };

  const handleLogin = async () => {
    const result = await loginUser(username, password);
    if (!result.success) {
      setError(result.error);
    }
  };

  const handleLogout = async () => {
    const result = await logoutUser();
    if (!result.success) {
      setError(result.error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button onClick={handleRegister}>Register</button>
      <button onClick={handleLogin}>Login</button>
      <button onClick={handleLogout}>Logout</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default AuthPage;
