import React, { useState, useEffect } from 'react';
import Parse from './ParseConfig';
import './Leaderboards.css';

const convertTimeToSeconds = (timeStr) => {
    const [minutes, seconds] = timeStr.split(':');
    return parseInt(minutes) * 60 + parseFloat(seconds);
};

const formatTotalTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    let seconds = (totalSeconds % 60).toFixed(3); 
    seconds = seconds.padStart(6, '0'); 
    return `${minutes}:${seconds}`;
} 

const processLeaderboardData = (times) => {
    let userScores = {};
    let trackRankings = {};

    times.forEach(time => {
        const track = time.get('track');
        const user = time.get('user').get('username');
        const timeValue = convertTimeToSeconds(time.get('time'));

        if (!trackRankings[track]) {
            trackRankings[track] = {};
        }
        if (!trackRankings[track][user] || trackRankings[track][user] > timeValue) {
            trackRankings[track][user] = timeValue;
        }
    });

    Object.keys(trackRankings).forEach(track => {
        console.log(`Track: ${track}`);
        Object.entries(trackRankings[track]).forEach(([user, time]) => {
            console.log(`${user}: ${time} seconds`);
        });
    });
    Object.keys(trackRankings).forEach(track => {
        const userTimes = Object.entries(trackRankings[track]);
        const sortedTimes = userTimes.sort(([, timeA], [, timeB]) => timeA - timeB);
        sortedTimes.forEach(([user], index) => {
            if (!userScores[user]) {
                userScores[user] = { firsts: 0, seconds: 0, thirds: 0, points: 0, totalTime: 0};
            }
            const points = index === 0 ? 3 : index === 1 ? 2 : index === 2 ? 1 : 0;
            userScores[user].points += points;
            userScores[user].totalTime += trackRankings[track][user];
            if (points === 3) userScores[user].firsts++;
            else if (points === 2) userScores[user].seconds++;
            else if (points === 1) userScores[user].thirds++;
        });
    });

    return Object.entries(userScores).map(([username, data]) => ({
        username,
        ...data,
        formattedTotalTime: formatTotalTime(data.totalTime)
    })).sort((a, b) => b.points - a.points);
};

function Leaderboards() {
    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
        const fetchAndProcessData = async () => {
            const Times = Parse.Object.extend('Times');
            const query = new Parse.Query(Times);
            query.include('user');
            query.limit(1000);
            try {
                const results = await query.find();
                const processedData = processLeaderboardData(results);
                setLeaderboardData(processedData);
            } catch (error) {
                console.error('Error fetching times: ', error);
            }
        };

        fetchAndProcessData();
    }, []); 

    return (
        <div className="leaderboards">
            <h2>Leaderboards</h2>
            <ul>
                {leaderboardData.map((user, index) => (
                    <li key={index}>
                        {user.username}: {user.points} points (1st: {user.firsts}, 2nd: {user.seconds}, 3rd: {user.thirds}, Total Time: {user.formattedTotalTime})
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Leaderboards;
