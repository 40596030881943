import React, { useState, useEffect, useCallback } from 'react';
import { cups } from './trackData';
import Parse from './ParseConfig';
import './Profile.css'; 

function Profile() {
    const [selectedCup, setSelectedCup] = useState(Object.keys(cups)[0]);
    const [selectedTrack, setSelectedTrack] = useState(cups[Object.keys(cups)[0]][0]);
    const [userTimes, setUserTimes] = useState([]);

    const fetchUserTimes = useCallback(async () => {
        const Times = Parse.Object.extend('Times');
        const query = new Parse.Query(Times);
        query.equalTo('track', selectedTrack);
        query.equalTo('user', Parse.User.current());
        try {
            const results = await query.find();
            setUserTimes(results.map(result => ({
                id: result.id,
                time: result.get('time'),
                date: result.get('date'),
                image: result.get('image') ? result.get('image').url() : null
            })));
        } catch (error) {
            console.error('Error fetching times: ', error);
        }
    }, [selectedTrack]);

    useEffect(() => {
        fetchUserTimes();
    }, [fetchUserTimes]);

    const handleCupChange = (e) => {
        setSelectedCup(e.target.value);
        setSelectedTrack(cups[e.target.value][0]);
    };

    const handleTrackChange = (e) => {
        setSelectedTrack(e.target.value);
    };

    const handleDelete = async (timeId) => {
        const Times = Parse.Object.extend('Times');
        const query = new Parse.Query(Times);
        const timeToDelete = await query.get(timeId);
        await timeToDelete.destroy();
        fetchUserTimes();
    }

    return (
        <div>
            <h2>My Records</h2>
            <label htmlFor="cup-select">Choose a Cup:</label>
            <select id="cup-select" value={selectedCup} onChange={handleCupChange}>
                {Object.keys(cups).map(cup => (
                    <option key={cup} value={cup}>{cup}</option>
                ))}
            </select>

            <label htmlFor="track-select">Choose a Track:</label>
            <select id="track-select" value={selectedTrack} onChange={handleTrackChange}>
                {cups[selectedCup].map(track => (
                    <option key={track} value={track}>{track}</option>
                ))}
            </select>

            <ul>
                {userTimes.map((entry, index) => (
                    <li key={entry.id}>
                        Record {index + 1}: {entry.time} on {entry.date}
                        
                        <div>
                            Laps: 
                            {entry.lap1Time || '--'} |
                            {entry.lap2Time || '--'} |
                            {entry.lap3Time || '--'}
                        </div>
                        {entry.image && <img src={entry.image} alt="Screenshot" />}
                        {Parse.User.current() && Parse.User.current().get('user') === entry.user && (
                        <button onClick={() => handleDelete(entry.id)}>Delete</button>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Profile;
