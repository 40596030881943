// src/LogPage.js
import React, { useState, useEffect } from 'react';
import Parse from './ParseConfig';
import './LogPage.css';

function LogPage() {

    // stores log entries fetched from Parse
    const [logEntries, setLogEntries] = useState([]); // set as empty array

    // calls fetchLogEntries when the component mounts.
    useEffect(() => {
        fetchLogEntries();
    }, []);

    const fetchLogEntries = async () => {
        const Times = Parse.Object.extend('Times');

        // create query
        const query = new Parse.Query(Times);
        query.include('user');
        query.descending('createdAt'); // to get the newest entries first
        query.limit(50); // limit the number of entries

        try {
            const results = await query.find();
            const entries = results.map(result => ({
                id: result.id,
                track: result.get('track'),
                time: result.get('time'),
                user: result.get('user').get('username'),
                createdAt: result.get('createdAt').toLocaleString() // format date
            }));
            setLogEntries(entries);
        } catch (error) {
            console.error('Error fetching log entries: ', error);
        }
    };

    return (
        <div className="log-page">
            <h2>Log</h2>
            <ul>
                {logEntries.map(entry => (
                    <li key={entry.id}>
                        {entry.user} added {entry.track} time of {entry.time} at {entry.createdAt}.
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default LogPage;
