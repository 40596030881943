// src/TrackDetails.js
import React, { useState, useEffect, useCallback} from 'react';
import { cups } from './trackData';
import Parse from './ParseConfig';
import './TrackDetails.css';

  function TrackDetails() {
    const cupNames = [
        'Mushroom Cup', // cup_0
        'Flower Cup',   // cup_1
        'Star Cup',     // cup_2
        'Special Cup',  // cup_3
        'Shell Cup',    // cup_4
        'Banana Cup',   // cup_5
        'Leaf Cup',     // cup_6
        'Lightning Cup' // cup_7
      ];
    // state variables
    const [selectedCup, setSelectedCup] = useState(Object.keys(cups)[0]);
    const [selectedTrack, setSelectedTrack] = useState(cups[Object.keys(cups)[0]][0]);
    const [times, setTimes] = useState([]);
    const [newTime, setNewTime] = useState('');
    const [newDate, setNewDate] = useState('');
    const [newImage, setNewImage] = useState('');
    const [lap1Time, setLap1Time] = useState('');
    const [lap2Time, setLap2Time] = useState('');
    const [lap3Time, setLap3Time] = useState('');
    const [error, setError] = useState('');
    const [imageError, setImageError] = useState('');
    

    const fetchTimes = useCallback(async () => {
        const Times = Parse.Object.extend('Times');
        const query = new Parse.Query(Times);
        query.equalTo('track', selectedTrack);
        query.include('user');
        try {
          const results = await query.find();
          const bestTimes = getBestTimesPerUser(results);
          setTimes(bestTimes);
        } catch (error) {
          console.error('Error fetching times: ', error);
        }
      }, [selectedTrack]);
    
      const getBestTimesPerUser = (results) => {
        const userBestTimes = {};
    
        results.forEach(result => {
          const username = result.get('user').get('username');
          const time = result.get('time');
          const imageFile = result.get('image');
          const imageUrl = imageFile ? imageFile.url() : null;
    
          if (!userBestTimes[username] || userBestTimes[username].time > time) {
            userBestTimes[username] = { ...result.attributes, id: result.id, user: username, image: imageUrl };
          }
        });
    
        return Object.values(userBestTimes).sort((a, b) => a.time.localeCompare(b.time));
      };

      const calculateTimeDifference = (time1, time2) => {

        if (!time1 || !time2){
          return "--";
        }
        const parseTime = (time) => {
            const [minutes, seconds] = time.split(':');
            return parseInt(minutes, 10) * 60 + parseFloat(seconds);
        };
    
        const diffInSeconds = parseTime(time2) - parseTime(time1);
        const sign = diffInSeconds >= 0 ? '+' : '-';
        const absDiff = Math.abs(diffInSeconds);
        const minutes = Math.floor(absDiff / 60).toString().padStart(2, '0');
        const seconds = (absDiff % 60).toFixed(3).padStart(6, '0');
    
        return `${sign}${minutes}:${seconds}`;
    };
    

    useEffect(() => {
        fetchTimes();
      }, [fetchTimes]);
      

    const addTime = async () => {
        if (!validateTime(newTime) || !validateDate(newDate)) {
            setError('Invalid input');
            return;
        }

        const Times = Parse.Object.extend('Times');
        const newTimeEntry = new Times();
        const currentUser = Parse.User.current();
        if (!currentUser) {
            setError('No logged-in user found');
            return;
        }

        newTimeEntry.set('track', selectedTrack);
        newTimeEntry.set('time', newTime);
        newTimeEntry.set('user', currentUser);
        newTimeEntry.set('date', newDate);
        if (newImage) {
          newTimeEntry.set('image', new Parse.File("screenshot.jpg", { base64: newImage }));
      }
        newTimeEntry.set('lap1Time', lap1Time);
        newTimeEntry.set('lap2Time', lap2Time);
        newTimeEntry.set('lap3Time', lap3Time);
 
        try {
            await newTimeEntry.save();
            setNewTime('');
            setNewDate('');
            setNewImage('');
            setLap1Time('');
            setLap2Time('');
            setLap3Time('');
            setError('');
            setImageError('');
            fetchTimes();
        } catch (error) {
            console.error('Error saving new time: ', error);
            setError('Error saving time: ' + error.message);
        }
    };
    
  

  const handleDelete = async (timeId) => {
    const Times = Parse.Object.extend('Times');
    const query = new Parse.Query(Times);
    try {
        const timeToDelete = await query.get(timeId);
        if (timeToDelete.get('user').id === Parse.User.current().id) {
            await timeToDelete.destroy();
            fetchTimes(); // refresh the list after deletion
        } else {
            setError('You can only delete your own records.');
        }
    } catch (error) {
        console.error('Error deleting time: ', error);
        setError('Error deleting time: ' + error.message);
    }
};

    const validateTime = (time) => {
        const regex = /^\d{1,2}:\d{2}\.\d{3}$/;
        return regex.test(time);
    };

    const validateDate = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
        return regex.test(date);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match('image.*')) {
            const reader = new FileReader();
            reader.onload = (readEvent) => {
                setNewImage(readEvent.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setNewImage('')
        }
    };

    const handleCupChange = (cupIndex) => {
        const cupName = cupNames[cupIndex];
        const firstTrack = cups[cupName][0];
        setSelectedCup(cupName);
        setSelectedTrack(firstTrack);
    };

    const handleTrackChange = (e) => {
        setSelectedTrack(e.target.value);
    };


    return (
      <div>
          <h2 style={{ color: 'white' }}>Records</h2>
          <label htmlFor="cup-select">Choose a Cup:</label>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', maxWidth: '275px', margin: 'auto' }}>
            {cupNames.map((cupName, index) => (
                <img 
                    key={cupName} 
                    src={`./cups/cup_${index}.png`} 
                    alt={`${cupName}`} 
                    style={{ width: 'calc(25% - 10px)', margin: '5px', cursor: 'pointer' }}
                    onClick={() => handleCupChange(index)} 
                />
            ))}
        </div>
  
          <label htmlFor="track-select">Choose a Track:</label>
          <select id="track-select" value={selectedTrack} onChange={handleTrackChange}>
              {cups[selectedCup].map(track => (
                  <option key={track} value={track}>{track}</option>
              ))}
          </select>
  
          <div>
              <h3>{selectedTrack}</h3>
              <input type="text" value={newTime} onChange={(e) => setNewTime(e.target.value)} placeholder="Enter track time (M:SS.sss)" />
              <input type="text" value={newDate} onChange={(e) => setNewDate(e.target.value)} placeholder="Enter date (MM/DD/YYYY)" />
              <input type="file" accept="image/*" onChange={handleImageUpload} />
              <input type="text" value={lap1Time} onChange={(e) => setLap1Time(e.target.value)} placeholder="Optional: Enter Lap 1 time (M:SS.sss)" />
              <input type="text" value={lap2Time} onChange={(e) => setLap2Time(e.target.value)} placeholder="Optional: Enter Lap 2 time (M:SS.sss)" />
              <input type="text" value={lap3Time} onChange={(e) => setLap3Time(e.target.value)} placeholder="Optional: Enter Lap 3 time (M:SS.sss)" />
              <button onClick={addTime}>Add Time</button>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
              
              {/* Unified times display */}
              {times.length > 0 && (
                  <ul className="top-three">
                      {times.slice(0, 3).map((entry, index, array) => renderTimeEntry(entry, index, array))}
                  </ul>
              )}
              {times.length > 3 && (
                  <ul className="rest-times">
                      {times.slice(3).map((entry, index, array) => renderTimeEntry(entry, index + 3, array))}
                  </ul>
              )}
          </div>
      </div>
  );
  
  function renderTimeEntry(entry, index, array) {
    const isTopThree = index < 3;
    const positionClass = isTopThree ? (index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze') : 'regular-times';
    const firstPlaceEntry = array[0];

    const formatTimeWithDiff = (entryTime, firstPlaceTime) => {

        if (entryTime === firstPlaceTime){
            return entryTime;
        }
        const timeDiff = calculateTimeDifference(firstPlaceTime, entryTime);
        const diffColor = timeDiff.startsWith('+') ? '#950000' : 'green';
        return (
            <>
                {entryTime} (<span style={{ color: diffColor }}>{timeDiff}</span>)
            </>
        );
    };

    return (
        <li key={entry.id} className={positionClass}>
            Rank {index + 1}: {formatTimeWithDiff(entry.time, firstPlaceEntry.time)} by {entry.user} on {entry.date}
            {entry.lap1Time || entry.lap2Time || entry.lap3Time ? (
                <div>
                    Splits:
                    <div>Lap 1: {entry.lap1Time ? formatTimeWithDiff(entry.lap1Time, firstPlaceEntry.lap1Time) : '--'}</div>
                    <div>Lap 2: {entry.lap2Time ? formatTimeWithDiff(entry.lap2Time, firstPlaceEntry.lap2Time) : '--'}</div>
                    <div>Lap 3: {entry.lap3Time ? formatTimeWithDiff(entry.lap3Time, firstPlaceEntry.lap3Time) : '--'}</div>
                </div>
            ) : (
                <div>No Splits Available</div>
            )}
            {entry.image && <img src={entry.image} alt="Screenshot" />}
            {Parse.User.current() && Parse.User.current().get('username') === entry.user && (
                <button onClick={() => handleDelete(entry.id)}>Delete</button>
            )}
        </li>
        );
    }

}
export default TrackDetails;